@import '../../styles/index';

.base {
  position: absolute;
  bottom: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $color-white--100;
  padding: 2.5rem 0.5rem 0.5rem;
  box-shadow:
    0 0 2px 0 rgba(0, 0, 0, 0.1),
    0 0 27px 0 rgba(0, 0, 0, 0.12);

  h3 {
    font-size: $font-size-24;
    line-height: 1;
    margin: 0 0 1.5rem;
  }

  table {
    display: block;
    overflow: hidden;
    max-height: 100px;
    transition: max-height 0.3s ease;
  }

  @include media-from(sm) {
    left: 2rem;
    bottom: 2rem;
    max-width: 483px;
    width: 100%;
    padding: 1.5rem;
    border-radius: $border-radius-huge;

    table {
      max-height: none;
    }
  }
}

.content {
  position: relative;
  overflow-y: auto;
  flex: 1;

  &.overlay {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 70%,
        #ffffff 100%
      );
      pointer-events: none;
    }

    @include media-from(sm) {
      &:before {
        content: none;
      }
    }
  }
}

.expanded {
  max-height: 80%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.65);
    animation: fadeIn 0.25s;
  }

  .content {
    &::before {
      content: none;
    }
  }

  table {
    max-height: 1000px;
  }

  @include media-from(sm) {
    &::before {
      content: none;
    }
  }
}

.expand-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: -25px;
  transform: translateX(-50%);
  background-color: $color-white--100;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  box-shadow: $shadow-standard;
  cursor: pointer;

  & > i {
    color: $color-surrogate--100;
  }

  @include media-from(sm) {
    display: none;
  }

  &.hidden {
    display: none;
  }
}

.close {
  color: $color-surrogate--100;
  cursor: pointer;
  display: block;
  position: absolute;
  right: 0.5rem;
  top: 0.925rem;
  z-index: 10;

  @include media-from(sm) {
    right: 1.5rem;
    top: 1.5rem;
  }
}

.image {
  border-radius: 0.75rem;
  margin-bottom: 1.5rem;
}

.type {
  --label-color: #{$color-surrogate--100};
  align-items: center;
  display: flex;
  font-weight: $font-weight-medium;
  gap: 0.75rem;
  margin-bottom: 0.75rem;

  .badge,
  .label {
    color: var(--label-color);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: $font-size-16;

    &.owned {
      --label-color: #{$color-surrogate--100};
    }

    &.managed {
      --label-color: #{$color-blue--100};
    }

    &.photovoltaic {
      --label-color: #{$color-yellow--100};
    }

    &.office {
      --label-color: #{$color-turquoise--100};
    }

    & > i {
      color: currentColor;
    }
  }

  .badge {
    background-color: $color-surrogate--05;
    border-radius: $border-radius-default;
    color: $color-surrogate--100;
    padding: 0.25rem 0.5rem;
  }
}

.table {
  cursor: pointer;
  width: 100%;

  tbody {
    display: table;
    width: 100%;
  }

  th {
    font-weight: $font-weight-medium;
    width: 45%;
  }

  td {
    color: $color-black--100;
    font-weight: $font-weight-light;
  }

  th,
  td {
    text-align: left;
    font-size: 15px;
    vertical-align: top;
  }

  @include media-from(sm) {
    cursor: auto;
  }
}

.fadeIn {
  animation: slideIn 0.25s;

  @include media-from(sm) {
    animation: fadeIn 0.25s;
  }
}

.fadeOut {
  animation: slideOut 0.25s;

  @include media-from(sm) {
    animation: fadeOut 0.25s;
  }
}

.hint {
  margin: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: none;
  }
}

@keyframes slideOut {
  from {
    transform: none;
  }
  to {
    transform: translateY(100%);
  }
}
