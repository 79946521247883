@import '../../styles/index';

.base {
  display: none;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: fit-content;
  z-index: 2;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.08),
    0 8px 16px 0 rgba(0, 0, 0, 0.08);

  &.visible {
    display: block;
  }

  @include media-from(xs) {
    display: block;
    top: 2rem;
    left: 2rem;
    width: max-content;
    max-width: calc(100% - 15rem);
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $color-white--100;
    padding: 2rem;
    gap: 1rem;

    @include media-from(xs) {
      border-radius: 0.5rem;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      padding: 1.25rem;
      gap: 2rem;
    }
  }

  .label {
    --label-color: #{$color-black--40};
    color: var(--label-color);
    display: flex;
    align-items: center;
    font-size: $font-size-18;
    cursor: pointer;

    &.active {
      &.owned {
        --label-color: #{$color-surrogate--100};
      }

      &.managed {
        --label-color: #{$color-blue--100};
      }

      &.photovoltaic {
        --label-color: #{$color-yellow--100};
      }

      &.office {
        --label-color: #{$color-turquoise--100};
      }
    }

    .checkbox {
      display: inline-block;
      margin-right: 0.75rem;

      > div {
        border-color: var(--label-color);

        &::before {
          background-color: var(--label-color);
        }
      }
    }
  }

  .toggle {
    display: flex;

    @include media-from(xs) {
      display: none;
    }
  }
}
